<template>
  <div>
    <div v-if="!isLoading">
      <LoadingProduct></LoadingProduct>
    </div>
    <div v-else>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <!-- 導覽列 -->
            <nav aria-label="breadcrumb" class="pt-2 pt-md-4 pb-0 pb-md-3 mb-2">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/productlist" class="d-block text-secondary fs-7 fs-md-6">全系列商品</router-link>
                </li>
                <li class="breadcrumb-item fs-7 fs-md-6 active" aria-current="page">{{ product.Name }}</li>
              </ol>
            </nav>
            <!-- 產品內容 -->
            <div class="d-lg-flex justify-content-between border-bottom border-secondary border-1 pb-4 pb-md-6 mb-4 mb-md-5">
              <!-- 產品圖片(單張) -->
              <div class="images mb-3 mb-lg-0">
                <!-- <img :src="product.Image" alt="商品圖片" class="img-fluid w-100"> -->
                <!-- 電腦版大圖 -->
                <div class="img w-100 ratio ratio-1x1 d-none d-lg-block">
                  <img
                    v-if="product.Image"
                    :src="product.Image"
                    alt=""
                  />
                </div>
                <!-- 手機版大圖 -->
                <div class="img w-100 ratio ratio-1x1 d-md-none" v-if="!product.ProductOtherImg">
                  <img
                    :src="product.Image"
                    alt=""
                  />
                </div>
                <!-- 電腦版多圖介紹幻燈片 -->
                <div class="mt-3 d-none d-lg-block productOtherImgPC" v-if="product.ProductOtherImg">
                  <swiper
                    :navigation="{
                      nextEl: '#js-next2',
                      prevEl: '#js-prev2'
                    }"
                    :spaceBetween="10"
                    :slidesPerView="2"
                    :breakpoints="{
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 10
                      },
                      992: {
                        slidesPerView: 4,
                        spaceBetween: 10
                      },
                      1200: {
                        slidesPerView: 5,
                        spaceBetween: 15
                      }
                    }"
                  >
                    <swiper-slide v-for="item in product.ProductOtherImg" :key="item.id">
                      <div class="ratio ratio-1x1">
                        <a href="#" @click.prevent="changeImg(item)">
                          <img class="w-100 h-100" :src="item.imgFilePath" alt="" />
                        </a>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <!-- navigation buttons -->
                  <div id="js-prev2" class="swiper-button-prev"></div>
                  <div id="js-next2" class="swiper-button-next"></div>
                </div>
                <!-- 手機版多圖介紹幻燈片 -->
                <div :class="{'d-none':!product.ProductOtherImg||!product.ProductOtherImg.length>1}" class="d-lg-none productOtherImgMB">
                  <swiper
                    :navigation="true"
                    :pagination="{
                      el: '#pagination',
                      type: 'fraction'
                    }"
                  >
                    <swiper-slide v-for="item in product.ProductOtherImg" :key="item">
                      <div class="ratio ratio-1x1">
                        <img v-if="!item.imageUrl" :src="item.imgFilePath" alt="" class="w-100">
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div id="pagination" class="swiper-pagination-fraction"></div>
                </div>
              </div>
              <!-- 產品圖片組 -->
              <!-- <div class="images">
                產品圖片組 - PC
                <div class="d-none d-lg-block">
                  <div class="position-relative">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#videoModal" v-if="productMainVideo" class="position-absolute top-50 start-50 translate-middle">
                      <img src="https://www.momoshop.com.tw/goods/images/video_icon2.png?t=20160318001" alt="play">
                    </a>
                    <img :src="productMainImage" class="img-fluid w-100">
                  </div>
                  影片 Modal (PC)
                  <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body p-0 position-relative">
                          <button type="button" class="videoModalClose btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div class="embed-responsive ratio ratio-16x9">
                            <iframe class="embed-responsive-item" :src="`${productMainVideo}?rel=0&amp;modestbranding=1`" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <swiper
                    :slidesPerView="2.3"
                    :spaceBetween="16"
                    class="productSwiper mt-3"
                    :navigation="true"
                    :breakpoints="{
                      768: {
                        slidesPerView: 4
                      }
                    }"
                  >
                    <swiper-slide v-for="image in product.Images" :key="image+'1'">
                      <a href="#" @click.prevent="changeMainImage(image)">
                        <img :src="image.ImageUrl" class="card-img-top" alt="product image">
                      </a>
                    </swiper-slide>
                  </swiper>
                </div>
                產品圖片組 - mobile
                <div class="d-lg-none mb-4 mb-sm-5 pb-4 position-relative">
                  <swiper
                    :slidesPerView="1"
                    :pagination= "{
                      el: '.swiper-pagination',
                      type: 'bullets',
                      clickable: true
                    }"
                    class="productMobileSwiper"
                  >
                    <swiper-slide v-for="(image, index) in product.Images" :key="image+'2'">
                      <a href="#" data-bs-toggle="modal" :data-bs-target="`#videoMobileModal${index+1}`" v-if="image.VideoUrl" class="position-absolute top-50 start-50 translate-middle">
                        <img src="https://www.momoshop.com.tw/goods/images/video_icon2.png?t=20160318001" alt="play">
                      </a>
                      <img :src="image.ImageUrl" class="card-img-top" alt="product image">
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-pagination start-50 translate-middle"></div>
                </div>
                影片 Modal (Mobile)
                <template v-if="product.Images">
                  <div class="modal fade" :id="`videoMobileModal${num}`" tabindex="-1" role="dialog" v-for="num in product.Images.length" :key="num+'1'">
                    <template v-if="product.Images[num - 1].VideoUrl">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-body p-0 position-relative">
                            <button type="button" class="videoMobileModalClose btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="embed-responsive ratio ratio-16x9">
                              <iframe class="embed-responsive-item" :src="`${product.Images[num - 1].VideoUrl}?rel=0&amp;modestbranding=1`" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>-->
              <!-- 產品詳細資訊 -->
              <div class="details d-flex flex-column justify-content-between mt-3 mt-md-0">
                <div>
                  <h1 class="fs-3 mb-4">{{ product.Name }}</h1>
                  <!-- <p class="mb-3 mb-md-4">容量: 60g</p> -->
                  <ul v-if="product.Tags" class="d-flex list-unstyled fw-light mb-3 mb-md-4">
                    <li class="feature me-2" v-for="tag in product.Tags" :key="tag+'2'">{{ tag }}</li>
                  </ul>
                  <div class="fs-10 mb-4 mb-md-6" v-html="product.ShortDescription"></div>
                </div>
                <div>
                  <!-- 活動說明  -->
                  <!-- <ul class="fs-10 list-unstyled text-primary border-start border-3 border-primary mb-4 mb-md-5 ps-3">
                    <li>消費滿$1,500送日系喜福手提包</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit doloremque soluta eius temporibus aut saepe.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, amet.</li>
                    <li>Lorem ipsum dolor sit amet.</li>
                    <li>Lorem ipsum dolor sit amet.</li>
                  </ul> -->
                  <!-- 贈品  -->
                  <div v-if="product.Gift">
                    <div class="d-flex align-items-start mb-3">
                      <p class="px-1 bg-primary text-white me-2 text-nowrap">下單送</p>
                      <ul class="gift-container text-muted">
                        <li v-for="item in product.Gift" :key="item.id">
                          {{ item.Name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="d-flex align-items-start mb-3" v-if="product.ConditionalGift?.length > 0">
                    <p class="px-1 bg-primary text-white text-nowrap me-2">滿額送</p>
                    <div class="list-unstyled text-muted">
                      <span v-html="product.GiftDesc"></span>
                      (不累贈，贈品數量有限，贈完為止)
                    </div>
                  </div>
                  <div class="border-top border-1 border-secondary pt-4 mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="text-danger fs-4">
                        NT$ <span class="fs-2 fs-lg-1">{{ $currency.currency(product.Price) }}</span>
                        <del class="text-secondary fs-6 ms-2">${{ $currency.currency(product.MSRP) }}</del>
                      </p>
                      <div class="linkGroup d-flex position-relative">
                        <!-- 未加入追蹤 -->
                        <!-- <a href="#" class="d-block" @click.prevent="addToTracklist" v-if="!isTracklist">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28.808" height="26.918" viewBox="0 0 19.808 17.918">
                            <path id="yesgo_icon元件-28" d="M19.44,6a4.8,4.8,0,0,0-9.19-1.9A4.8,4.8,0,1,0,2.51,9.47l7.74,8.29L18,9.47A4.8,4.8,0,0,0,19.44,6Z" transform="translate(-0.307 -0.517)" fill="none" stroke="#4d4d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.35"/>
                          </svg>
                        </a> -->
                        <!-- 已加入追蹤 -->
                        <!-- <a href="#" class="d-block" @click.prevent="removeToTracklist" v-if="isTracklist">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28.808" height="26.918" viewBox="0 0 19.808 18.233">
                            <path id="yesgo_icon元件-29" d="M19.44,5.88a4.8,4.8,0,0,0-9.19-1.94A4.8,4.8,0,1,0,2.51,9.31l7.74,8.29L18,9.31A4.78,4.78,0,0,0,19.44,5.88Z" transform="translate(-0.307 -0.355)" fill="#f8412e" stroke="#f8412e" stroke-miterlimit="10" stroke-width="1.35"/>
                          </svg>
                        </a> -->
                        <a v-if="!showSocialLinks" href="#" class="d-block sharelink ms-3" @click.prevent="showSocialLinks = !showSocialLinks">
                          <img src="../../assets/img/Icons-08.svg" alt="share links">
                        </a>
                        <a v-else href="#" class="d-block sharelink ms-3" @click.prevent="showSocialLinks = !showSocialLinks">
                          <img src="@/assets/img/Icons-15.svg" alt="close icon">
                        </a>
                        <!-- 社群連結分享 -->
                        <transition name="fade">
                          <div v-if ="showSocialLinks" class="shareBlock position-absolute">
                            <SocialIcons :product-id="this.$route.params.productId" :name="product.Name"></SocialIcons>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                  <p v-if="!product.ForSale" class="fs-4 text-primary">此商品尚未開賣</p>
                  <p v-else-if="realStock === 0" class="fs-4 text-primary">已售完</p>
                  <p v-else-if="displayStock === 0" class="fs-4 text-primary">售完補貨中</p>
                  <div v-else class="row g-3">
                    <div class="col-6">
                      <!-- 數量 -->
                      <div class="productNumber input-group">
                        <button
                          type="button"
                          class="btn border-dark rounded-0"
                          @click.prevent="minusNum"
                          :class="{'disabled': productNum === 1}"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          class="form-control border-dark text-center fs-6 fs-lg-5 py-2"
                          aria-label="product number"
                          id="num"
                          min="1"
                          v-model.number="productNum"
                          @input="productNumInput"
                        />
                        <button type="button" class="btn border-dark rounded-0" @click.prevent="addCartNum" :class="{'disabled': productNum === 0}">
                          +
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <!-- 加入購物車 -->
                      <div ref="addCartBtn" class="d-grid gap-2">
                        <button class="btn btn-primary rounded-0 fs-6 fs-lg-5 py-2" type="button" @click.prevent="CheckAddToCart()">加入購物車</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 加購商品區 -->
            <template v-if="isEventPrd">
              <AddonProductList class="my-3 my-md-5" :product-num="productNum" @get-addon="getAddon"></AddonProductList>
            </template>
            <!-- 商品描述 / 商品規格 -->
            <ul class="productDescription nav nav-pills text-start text-md-center mb-3 mb-md-4" id="pills-tab" role="tablist">
              <li class="nav-item d-inline-block" role="presentation">
                <button class="nav-link rounded-0 fw-normal fw-md-medium text-dark fs-4 fs-sm-3 fs-md-2 fs-lg-4 px-0 mx-md-3" id="pills-home-tab" type="button" role="tab" data-bs-toggle="pill" data-bs-target="#pills-tabDescription" @click.prevent="changeDescription = !changeDescription" :class="{ active: changeDescription }">商品描述</button>
              </li>
              <li class="nav-item d-inline-block" role="presentation">
                <button class="nav-link rounded-0 fw-normal fw-md-medium text-dark fs-4 fs-sm-3 fs-md-2 fs-lg-4 px-0 ms-4 mx-md-3" id="pills-profile-tab" type="button" role="tab" data-bs-toggle="pill" data-bs-target="#pills-tabSpecification" @click.prevent="changeDescription = !changeDescription" :class="{ active: !changeDescription}">商品規格</button>
              </li>
            </ul>
            <div class="productDescriptionContent tab-content mx-auto mb-6">
              <div class="tab-pane fade" :class="{ show: changeDescription, active: changeDescription }" aria-labelledby="pills-home-tab" id="pills-tabDescription">
                <div class="text-dark descriptionContent" v-html="product.Description"></div>
              </div>
              <div class="tab-pane fade"  :class="{ show: !changeDescription, active: !changeDescription }" aria-labelledby="pills-profile-tab" id="pills-tabSpecification">
                <div class="h-md-100 text-dark specificationContent" v-html="product.Specification"></div>
              </div>
            </div>
            <!-- 相關推薦 -->
            <h2 class="text-center mb-5">
              <span class="fs-4 fs-sm-3 fs-md-2 fs-lg-4 fw-medium border-bottom border-primary border-2 d-inlink-block pb-2"
                >相關推薦</span
              >
            </h2>
            <relativeProductList class="mb-5"></relativeProductList>
          </div>
        </div>
      </div>

      <!-- 加入購物車 -->
      <div class="d-grid gap-2 position-fixed bottom-0 start-0 w-100 z-index-3" v-if="showAddCart">
        <button class="btn btn-primary rounded-0 fs-5" type="button" @click.prevent="CheckAddToCart()">加入購物車</button>
      </div>
    </div>
  </div>
</template>

<script>
import relativeProductList from '@/components/RelativeProductList.vue'
import SocialIcons from '@/components/SocialIcons.vue'
import AddonProductList from '@/components/AddonProductList.vue'
import LoadingProduct from '@/views/Loading/LoadingProduct.vue'
import { currency } from '@/assets/js/currency.js'
import ProductSvc from '@/service/product-service.js'
import TrackListSvc from '@/service/trackList-service.js'
import CartSvc from '@/service/cart-service.js'
import checkVideoSrc from '@/assets/js/checkVideoSrc.js'

export default {
  data () {
    return {
      product: {},
      productId: '',
      addonProduct: [], //* 加購品
      showAddCart: false,
      showSocialLinks: false,
      changeDescription: true,
      isTracklist: false,
      cartBtn: '', //* 加入購物車按鈕 DOM
      productMainImage: '', //* 商品大圖
      productMainVideo: '', //* 商品大圖影片
      productNum: 1, //* 商品數量
      isLoading: false, // ?進場動畫開關
      optionSelected: '',
      title: '', //* 存放各頁面title
      realStock: 0, //* 實際庫存
      displayStock: 0, //* 顯示庫存
      isEventPrd: false
    }
  },
  components: {
    relativeProductList,
    SocialIcons,
    LoadingProduct,
    AddonProductList
  },
  methods: {
    getProduct () {
      ProductSvc.GetProduct(this.productId).then(data => {
        this.isLoading = true
        if (!data) {
          this.$router.push('/productlist')
          return
        }
        this.checkCookie()
        this.product = data
        console.log('product', data.Gift)
        // * 滿額贈
        if (this.product.ConditionalGift) {
          this.product.GiftDesc = this.product.ConditionalGift.map((content) => `單筆結帳滿$ ${currency(content.LowerLimit)}送 ${content.Name}<br>`).join('')
        }
        // ? Facebook Pixel 傳入商品 ID 及價格(ViewContent)
        const dataToFbq = {
          content_ids: this.product.Id,
          content_type: 'product',
          value: this.product.Price,
          currency: 'TWD'
        }
        window.fbq('track', 'ViewContent', dataToFbq)
        // * GA追蹤程式
        // * GA追蹤碼埋入
        this.title.textContent = `papaWash - ${this.product.Name}` // ?title更新為當頁商品
        this.setGA() // ? 呼叫vue-gTag傳入 title
        // * GA追蹤事件埋入
        this.GA_view_item()
        /* 多圖片組 */
        // this.productMainImage = this.product.Images[0].ImageUrl
        // this.productMainVideo = this.product.Images[0].VideoUrl || ''
        /* 商品品項，先都給第一筆的品項，後續有品項的選項再加入品項選擇 */
        if (this.product.Options.length > 0) {
          this.optionSelected = this.product.Options[0]
          this.realStock = this.product.Options[0].RealStock
          this.displayStock = this.product.Options[0].DisplayStock
          if (this.product.Options[0].Stock === 0) {
            this.productNum = 0
          }
        }
        // * 判斷是否為活動商品(A, B)
        if (this.product.Type === 'A') {
          this.isEventPrd = true
        } else if (this.product.Type === 'B') {
          this.$router.push('/productlist')
        }
        setTimeout(() => {
          // *商品描述圖片設定為寬度 100%
          const url = document.querySelectorAll('.descriptionContent img')
          if (url.length >= 0) {
            url.forEach(item => {
              item.classList.add('w-100')
            })
          }
          // *商品規格圖片設定為寬度 100%
          const url2 = document.querySelectorAll('.specificationContent img')
          if (url2.length >= 0) {
            url2.forEach(item => {
              item.classList.add('w-100')
            })
          }
          // *商品影片改為 video tag
          const url3 = document.querySelectorAll('oembed[url]')
          if (url3.length >= 0) {
            url3.forEach((item) => {
              const res = checkVideoSrc(item.getAttribute('url'))

              if (res.src === 'yt') {
                /* YT */
                const videoLable = document.createElement('div')
                videoLable.setAttribute('class', 'ratio ratio-16x9 mb-3')
                const videoSource = document.createElement('iframe')
                videoSource.setAttribute('src', `${res.url}`)
                videoSource.setAttribute('style', 'width: 100%;')
                videoSource.setAttribute('allowFullScreen', '')
                videoSource.setAttribute('frameBorder', 0)
                videoLable.appendChild(videoSource)
                item.appendChild(videoLable)
              } else if (res.src === 'aws') {
                /* mp4 */
                const videoLable = document.createElement('video')
                videoLable.setAttribute('loop', 'true')
                videoLable.setAttribute('class', 'w-100')
                videoLable.setAttribute('controls', '')
                videoLable.setAttribute('muted', '')
                videoLable.setAttribute('playsinline', '')
                const videoSource = document.createElement('source')
                videoSource.setAttribute('src', `${res.url}#t=0.001`)
                videoSource.setAttribute('type', 'video/mp4')
                videoLable.appendChild(videoSource)
                item.appendChild(videoLable)
              }
            })
          }
          // *商品圖片
          const url4 = document.querySelectorAll('figure')
          if (url4.length >= 0) {
            url4.forEach(item => {
              item.classList.add('mb-0')
            })
          }
        }, 500)
      })
    },
    getAddon (products) {
      this.addonProduct = products
    },
    addCartScroll () {
      this.cartBtn = this.$refs.addCartBtn
      if (this.cartBtn) {
        //* 手機版在加入購物車按鈕消失後，下方出現置底的加入購物車按鈕
        const windowY = window.scrollY//* 捲軸高度
        const btnHeight = this.cartBtn.offsetTop + 42//* 加入購物車按鈕高
        if (document.body.clientWidth < 992) {
          if (windowY > btnHeight) {
            this.showAddCart = true
          } else {
            this.showAddCart = false
          }
        }
      }
    },
    changeMainImage (image) { //* 切換主要商品圖片
      this.productMainImage = image.ImageUrl
      this.productMainVideo = image.VideoUrl
    },
    addCartNum () { //* 購物車數量 ++
      this.productNum++
      // * 無品項
      if (this.optionSelected === '') {
        this.$swal.fire({
          title: '請選擇品項',
          allowOutsideClick: true,
          confirmButtonColor: '#03894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        this.productNum = 1
        return
      }
      //* 考慮庫存數量
      const stockLimit = this.optionSelected.DisplayStock > this.optionSelected.RealStock ? this.optionSelected.RealStock : this.optionSelected.DisplayStock

      if (this.productNum > stockLimit) {
        this.productNum = stockLimit
      }
    },
    minusNum () { //* 購物車數量 --
      this.productNum--
      if (this.productNum < 1) {
        this.productNum = 1
      }
    },
    // * 使用者直接輸入數量
    productNumInput () {
      const stockLimit = this.optionSelected.DisplayStock > this.optionSelected.RealStock
        ? this.optionSelected.RealStock
        : this.optionSelected.DisplayStock

      if (this.productNum > stockLimit) {
        this.productNum = stockLimit // 自動調整為庫存上限
      }
      if (this.productNum < 1) {
        this.productNum = 1 // 防止數量少於 1
      }
    },
    checkInTrackList () { //* 查詢產品是否存在願望清單
      TrackListSvc.CheckInTrackList(this.$route.params.productId).then(data => {
        if (data) {
          this.isTracklist = true
        }
      })
    },
    addToTracklist () { //* 加入追蹤清單
      const track = {
        id: this.$route.params.productId,
        optionId: this.optionSelected.Id,
        price: this.product.Price,
        stock: 1
      }
      TrackListSvc.AddToTrackList(track).then((data) => {
        if (data) {
          this.isTracklist = true
        }
      })
    },
    removeToTracklist () { //* 移除追蹤清單
      const track = {
        id: this.product.Id
      }
      TrackListSvc.RemoveToTrackList(track).then((data) => {
        if (data) {
          this.isTracklist = false
        }
      })
    },
    //* 加入購物車判斷單品或多品加入
    CheckAddToCart () {
      if (this.product.Type === 'A') {
        this.addMultipleToCart()
      } else if (this.product.Type === 'C') {
        this.addToCart()
      }
    },
    //* 加入購物車(單品)
    addToCart () {
      const prd = {
        productId: this.productId,
        optionId: this.optionSelected.Id,
        productName: this.product.Name,
        costPrice: this.product.MSRP,
        sellPrice: this.product.Price
      }
      const num = this.productNum
      this.addtoCartFbq()
      this.GA_add_to_cart()
      CartSvc.AddToCart(prd, num).then((data) => {
        if (data.isSuccess) {
          this.$swal.fire({
            toast: true,
            position: 'center',
            icon: 'success',
            title: '商品已成功加入購物車',
            showConfirmButton: false,
            timer: 1500,
            width: 500,
            background: '#F0F0F2',
            padding: 25
          })
        }
      })
    },
    //* 加入購物車(多品)
    addMultipleToCart () {
      // * 主商品與加購品數量一致
      // ? 加入有活動的判斷
      const sumNum = this.addonProduct.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )

      if (sumNum !== this.productNum) {
        this.$swal.fire({
          title: '加購品數量需與主商品數量一致',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }
      this.product.Quantity = this.productNum || 1
      this.product.OptionId = this.product.Options[0].Id
      const prd = [this.product, ...this.addonProduct]
      this.addtoCartFbq()
      this.GA_add_to_cart()
      CartSvc.AddMultipleToCart(prd).then((data) => {
        if (data.isSuccess) {
          this.$swal.fire({
            toast: true,
            position: 'center',
            icon: 'success',
            title: '商品已成功加入購物車',
            showConfirmButton: false,
            timer: 1500,
            width: 500,
            background: '#F0F0F2',
            padding: 25
          })
        }
      })
    },
    checkCookie () {
      const AccessToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (AccessToken || RefreshToken) {
        this.checkInTrackList()
      }
    },
    setGA () { // ?GA追蹤
      this.$gtag.set({
        page_title: `papaWash - ${this.product.Name}`
      })
    },
    // ? Facebook Pixel 傳入商品 ID 及價格
    addtoCartFbq () {
      const toFbqData = {
        content_name: this.product.Name,
        content_ids: this.product.Id,
        content_type: 'product',
        value: this.product.Price,
        currency: 'TWD'
      }
      window.fbq('track', 'AddToCart', toFbqData)
    },
    GA_view_item () {
      const dataForGA = {
        items: [{}]
      }
      dataForGA.items[0] = JSON.parse(JSON.stringify(this.product))
      dataForGA.items[0].item_id = dataForGA.items[0].Id // ?產品代碼
      dataForGA.items[0].item_name = dataForGA.items[0].Name // ?產品名稱
      dataForGA.items[0].price = dataForGA.items[0].Price // ?末端售價
      // dataForGA.items[0].quantity = dataForGA.items[0].Options[0].Stock // ?庫存
      dataForGA.items[0].discount = (dataForGA.items[0].MSRP - dataForGA.items[0].Price) / dataForGA.items[0].MSRP // ?折扣率
      delete dataForGA.items[0].Date
      delete dataForGA.items[0].Image
      delete dataForGA.items[0].Slogan
      delete dataForGA.items[0].Id
      delete dataForGA.items[0].Name
      delete dataForGA.items[0].Options
      delete dataForGA.items[0].MSRP
      delete dataForGA.items[0].Description
      delete dataForGA.items[0].ShortDescription
      delete dataForGA.items[0].Specification
      delete dataForGA.items[0].Tags
      delete dataForGA.items[0].Gift
      delete dataForGA.items[0].Price
      this.$gtag.event('view_item', dataForGA)
    },
    GA_add_to_cart () {
      const dataForGA = {
        items: [{}]
      }
      dataForGA.items[0] = JSON.parse(JSON.stringify(this.product))
      dataForGA.items[0].item_id = dataForGA.items[0].Id // ?產品代碼
      dataForGA.items[0].item_name = dataForGA.items[0].Name // ?產品名稱
      dataForGA.items[0].price = dataForGA.items[0].Price // ?末端售價
      dataForGA.items[0].quantity = this.productNum // ?所購買數量
      dataForGA.items[0].discount = (dataForGA.items[0].MSRP - dataForGA.items[0].Price) / dataForGA.items[0].MSRP // ?折扣率
      delete dataForGA.items[0].Date
      delete dataForGA.items[0].Image
      delete dataForGA.items[0].Slogan
      delete dataForGA.items[0].Id
      delete dataForGA.items[0].Name
      delete dataForGA.items[0].Options
      delete dataForGA.items[0].MSRP
      delete dataForGA.items[0].Description
      delete dataForGA.items[0].ShortDescription
      delete dataForGA.items[0].Specification
      delete dataForGA.items[0].Tags
      delete dataForGA.items[0].Gift
      delete dataForGA.items[0].Price
      this.$gtag.event('add_to_cart', dataForGA)
    },
    changeImg (item) {
      if (item.imageUrl) {
        this.product.Image = ''
        return
      }
      this.product.Image = item.imgFilePath
    }
  },
  watch: {
    '$route' (to) {
      if (to.fullPath.match('/product/')) {
        this.productMainImage = ''
        this.productMainVideo = ''
        this.showAddCart = false
        this.productId = this.$route.params.productId
        this.getProduct()
      }
    }
  },
  created () {
    this.productId = this.$route.params.productId
  },
  mounted () {
    this.getProduct()
    if (document.body.clientWidth < 992) {
      window.addEventListener('scroll', this.addCartScroll)
    }
    this.title = document.querySelector('title') // ?取得 <title> DOM 元素 for GA
  },
  unmounted () {
    if (document.body.clientWidth < 992) {
      window.removeEventListener('scroll', this.addCartScroll)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_product';

.productOtherImgPC ,.productOtherImgMB {
  ::v-deep(.swiper-container){
    padding: 1rem 0 1rem 0;
    }

    ::v-deep(.swiper-container-horizontal>.swiper-pagination-progressbar, .swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite){
    // background: rgba(248, 65, 46,0.5);
    background: rgba(95, 95, 94, 0.5);
    // margin: 0 auto;
    width: 4rem;
    height: 5px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    top: 98%;
    // z-index: 10;
    }

    ::v-deep(.swiper-pagination-progressbar .swiper-pagination-progressbar-fill){
      background: #F8412E;
      border-radius: 10px;
    }

    ::v-deep(.swiper-button-next:after),::v-deep(.swiper-button-prev:after)
    {
      font-weight: bolder;
      font-size: 16px;
      padding: 28px 4px;
      background: rgba(256, 256, 256, .9);
      border-radius: 4px;
    }
}

/* 桌機多圖 - 將 swiper navigation 箭頭放置外側 */
.productOtherImgPC{
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

::v-deep(.productOtherImgPC .swiper-button-next){
  position: absolute;
  right: -10px;
}

::v-deep(.productOtherImgPC .swiper-button-prev){
  position: absolute;
  left: -10px;
}

.productOtherImgMB{
  position: relative;
}

::v-deep(.productOtherImgMB .swiper-button-next){
  right: 10;
}

::v-deep(.productOtherImgMB .swiper-button-prev){
  left: 10;
}

::v-deep(.productOtherImgMB .swiper-pagination-fraction){
  position: absolute;
  z-index: 10;
  width: fit-content;
  right: 10px;
  left: auto;
  bottom: 5px;
  color: #fff;
  background-color: rgba(0,0,0,0.3);
  border-radius: 30%;
  padding: 5px;
  font-size: 14px;
}

::v-deep(.productOtherImgMB .swiper-button-next:after),::v-deep(.productOtherImgMB .swiper-button-prev:after){
  border-radius: 50%;
  padding: 10px 14px;
  color: #fff;
  background-color: rgba(0,0,0,0.3);
}

.gift-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.gift-container li {
  list-style: none;
  padding: 0;
}
</style>
